import React, {Fragment, useState, useEffect, useContext, lazy, Suspense} from "react"
import {AuthContext} from "../../../../contexts/Authentication/AuthStateProvider"
import GmapChart from "../../../Charts/GmapChart"
import HealthCategoryService from "../../HealthCategoryService"
import ApexChart from "../../../Charts/ApexChart"
import {ECharts} from "../../../Charts/ECharts"
import {DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import PreLoadSpinner from "../../../PreLoadSpinner"
import LeaderShipDashboard from "../../Dashboard/LeaderShipDashboard"
import useStyle from "../../../../hooks/useStyle"
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import {GraphMetricsList, PresetDateRange, MaternalPregnancyFilter} from "../../../../constants/DataSourceList"
import {TDInputMultiSelect, CheckBox} from "../../../Common/FormInput"
import {Filters, SelectedFilterItems} from "./Filters"
import FilterSlider from "../../../Common/FilterSlider"
import useModal from '../../../../hooks/useModal'
import { ChatGrayIcon, CheckListGrayIcon, LinkFontAwesomeIcon } from "../../../Common/ImageIcons"
import CommentsModal from "../../../Modals/CommentsModal"
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import {CopyRight} from "../../../Common/CopyRight"
import { lazily } from 'react-lazily'
import { useCurrentUserHook } from "../../../Authentication/useUserHook"
const { MCJCaseByMedicalFacility } = lazily(
  () => import("../../../Organizations/MedicalCareFacility/MedicalFacilityReport")
);

let requestParams = null
let selectedChart = null
const KPICharts = ({ isFilterOpen, toggleFilter, activeTab, chartsColors }) => {

  const {currentUser} = useCurrentUserHook()

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filter, setFilter] = useState({ data_filter_types: ['zscore_statics'] })
  const [charts, setCharts] = useState([])
  const [reportType, setReportType] = useState('kpis')

  //const {isOpen: isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen: isChecklistOpen, toggleModal: toggleChecklistModal } = useModal()
  const { isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()

  /*useEffect(() => {
    toggleFilterModal()
  }, [isFilterBtnClick])*/

  useEffect(()=>{
    requestParams = {
      filter_type:reportType,
      data_filter_types:filter.data_filter_types,
      start_date:filter.start_date,
      end_date:filter.end_date,
      data_range:filter.data_range,
      district:currentUser.current_organization?.data?.district,
      talukas:filter.talukas,  
      villages:filter.villages,  
      medical_facility_ids:filter.medical_facility_ids 
    }
  	getReport()
  }, [filter, reportType])

  const getReport = ()=>{
  	setLoading(true)
  	HealthCategoryService.getInfantInsights(requestParams).then((res)=>{
      setData(res.data.reports)
      setCharts(MaternalPregnancyFilter.filter(i => filter.data_filter_types.indexOf(i.sid) > -1))
	    setLoading(false)			
	  })
  }

  const metricsView = GraphMetricsList.map((m, i)=>
  	<div key={i} className="font-15 p-5" onClick={()=>{setLoading(true);setReportType(m.sid)}}>
  	  <i className={`fas ${m.sid == reportType?'fa-check-circle':'fa-circle'} m-r-5 highlight`}/>
  	  {m.label} 
  	</div>
  )
  
  const openNotes = (type, callback) =>{
    selectedChart = {
      id:currentUser.current_organization_id,
      type:type
    }
    
    if(callback === 'comment'){
      toggleCommentModal()
    }else if(callback === 'checklist'){
      toggleChecklistModal()
    }
  }

  return(
    <>
      <div className="app-content-section">
        <div className="col-md-12">
          <div className="bg-white brd-10 m-b-30">
            <div className="card-title-gray flex coldir p10">
              <h4 className="fw700 f18 text-left">
                Geographic Analysis - {requestParams?.district}
              </h4>
              <SelectedFilterItems list={filter.talukas} name="talukas"/>
              <SelectedFilterItems list={filter.villages} name="villages"/>
              <span>{filter.start_date || 'Start Date Not Selected'} to {filter.end_date || 'End Date Not Selected'}</span>
            </div>
            <div className="card-body p20 clearfix">
              {loading ? 
                <div className="spinner"/>
                :
                <>
                  {charts.map((c, i) => 
                    <Card c={c} data={data} key={i} index={i} openNotes={openNotes} chartsColors={chartsColors}/>
                  )}  
                </>
              }
            </div>
          </div>
        </div>
      </div>  
      {(isFilterOpen && activeTab === 'kpis') && 
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={()=>toggleFilter()}
          chartFilter={filter}
          setChartFilter={setFilter}
          onFilterSubmit={getReport}
          activeTab={activeTab}/>
      }
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} 
        item={selectedChart} 
        type={selectedChart.type}/>
      }
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedChart} 
        type={selectedChart.type}/>
      }
    </>
  )
}

const Card = ({c, data, index, openNotes, chartsColors}) =>{
  const [legend, setLegend] = useState({show:true})
  if(c.chart === 'table'){
    return(
      <div className="col-md-12 m-t-5 m-b-5">
        <WhoMedianTable zscoreStatics={data[c.data_key]}/>
      </div>
    )
  }else{
    return(
    
          <div className="col-xs-12 col-sm-6 m-b-5">
        <div className="br-grey wk-center bg-white w-full">
          <Chart {...{c, data, index, openNotes, legend, chartsColors}}/>
          <div className="card-footer flex justspacebetween p20 aligncenter">
            <ul className="flex aligncenter">
              <li className="m-r-25" data-tip="Chat">
                <ChatGrayIcon onSelect={()=>openNotes(c.sid+'_chart', 'comment')}/>
              </li>
              <li className="m-r-25" data-tip="Checklist">
                <CheckListGrayIcon onSelect={()=>openNotes(c.sid+'_chart', 'checklist')}/>
              </li>
            </ul>
            {c.chart === 'pie' ?
              <button className="btn bg-grey m-r-10" onClick={()=>setLegend({show:!legend.show})}>
                Legends
              </button>
              : null
            }
          </div>
        </div>
      </div>
    )
  }
}

const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

const Chart = ({c, data, index, openNotes, legend, chartsColors}) =>{
  if(data[c.data_key][c.sid]){
    if(c.chart === 'pie'){
      return(
        <ECharts chartId={`chart_${index}`} 
          data={data[c.data_key][c.sid]} 
          dataset={{'dimensions':['x', 'y']}}
          chartType={c.chart}
          legend={legend}
          colors={chartsColors}
          {...(c?.chartOption||{})}
          formatter={(p)=>`${p.data.x} - ${p.data.y}`}
          height={650}/>
      )
    }else if(c.chart === 'treemap'){
      const reformed = []
      const d = data[c.data_key][c.sid]
      for (let i = 0; i < d?.length; i++) {
        reformed.push({'name':d[i].x, 'value':d[i].y})
      }
      return(
        <ECharts chartId={`chart_${index}`} 
          data={reformed} 
          chartType={c.chart}
          {...(c?.chartOption||{})}
          formatter={c?.chartOption?.formatter}
          labelFormatter={c?.chartOption?.labelFormatter}
          height={650}/>
      )
    }else if(c.chart === 'line'){
      c.chartOption.xAxis.data = data[c.data_key][c.sid]?.dates || []
      return(
        <ECharts chartId={`chart_${index}`} 
          data={data[c.data_key][c.sid]?.values} 
          chartType={c.chart}
          {...(c?.chartOption||{})}
          height={650}
        />
      )
    }else if(c.chart === 'bar-y-category-stack'){
      const datapoints = data[c.data_key][c.sid];
      
      /*Set y axis*/
      c.chartOption.yAxis.data = range(datapoints[0]?.min_y, datapoints[0]?.max_y, 1);
      
      const reformedData = [];      
      /*Set data*/
      for (let d of datapoints) {
        if(d.name){
          reformedData.push({
            name: d.name,
            type: 'bar',
            stack: 'total',
            label: {
              show: true
            },
            emphasis: {
              focus: 'series'
            },
            data: d.data
          })
        }
      }

      return(
        <ECharts chartId={`chart_${index}`} 
          data={reformedData} 
          chartType={c.chart}
          {...(c?.chartOption||{})}
          height={650}/>
      )
    }else if(c.chart === 'custom' && c.sid === 'cum_case_count'){
      return(
        <Suspense fallback={<div className="spinner"/>}>
          <MCJCaseByMedicalFacility chartId={`chart_${index}`} data={data[c.data_key][c.sid]}/>
        </Suspense>
      )
    }else{
      return(
        <ECharts chartId={`chart_${index}`} 
          data={data[c.data_key][c.sid]} 
          chartType={c.chart}
          {...(c?.chartOption||{})}
          height={650}/>
      )
      
      {/*return(
        <ApexChart id={`chart_${index}`} chartType={c.chart} 
        data={data[c.data_key][c.sid]} legend={legend} 
        chartWidth={c.chartWidth} title={c.label}/>
      )*/}
    }
  }else{
    return(
      <div className="ht-400">
        <h4>No Data</h4>
      </div>
    )
  }
}

const NotificationChart = () =>{
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()

  useEffect(()=>{
    HealthCategoryService.getNotificationReports().then(({status, data})=>{
      if(status == 200){
        setData(data)
        setLoading(false)
      }
    })
  }, [])

  return(
    <div className="page-content row p-5">
      {loading ? <div className="spinner"/>:
        <>
          <div className="col-md-6 col-sm-12 m-b-5 p-10">
            <ApexChart id="chat_1" reportType="bar" title="Planner" data={data.planner} 
            className="br-grey ht-400 wk-center bg-white w-full" />
          </div>
           
          <div className="col-md-6 col-sm-12 m-b-5 p-10"> 
            <ApexChart id="chat_4" reportType="pie" title="Counseling" data={data.counseling} 
            className="br-grey ht-400 wk-center bg-white w-full"/>
          </div>
        </>
      }
    </div>
  )    
}

const WhoMedianTable = ({zscoreStatics = {}}) =>{

  const calPercentage = (partialValue, totalValue) =>{
    return Math.round((100 * partialValue) / totalValue)
  }

  return (
    <div className="br-grey bg-white">
      <div className="p-5 text-center font-16 br-btm-grey fw700">
        Child nutrition status in specified area*
      </div>
      <div className="table-sm table-responsive">
        <Table className="shadow-small">
          <THead>
            <TR>
              <th className='p-2 bold-400 text-center miw-95' colSpan="1"></th>
              <th className='p-2 bold-400 text-center miw-95 bg-ltint br-white' colSpan="4">Z score (weight)</th>
              <th className='p-2 bold-400 text-center miw-95 bg-ltint br-white' colSpan="4">Z score (length)</th>
              <th className='p-2 bold-400 text-center miw-95 bg-ltint br-white' colSpan="4">Z score (weight/length)</th>
            </TR>
            <TR>
              <TH className='p-2 bold-400 text-center miw-95'>
                
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                SUW 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                MUW 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                Mild
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                Normal 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                SS 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                MS 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                Mild 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                Normal 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                SAM 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                MAM 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                Mild 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                Normal 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              <TD className="text-center">Number of cases</TD>
              <TD className="text-center">{zscoreStatics.suw}</TD>
              <TD className="text-center">{zscoreStatics.muw}</TD>
              <TD className="text-center">{zscoreStatics.wfa_mild}</TD>
              <TD className="text-center">{zscoreStatics.wfa_normal}</TD>
              <TD className="text-center">{zscoreStatics.ss}</TD>
              <TD className="text-center">{zscoreStatics.ms}</TD>
              <TD className="text-center">{zscoreStatics.hfa_mild}</TD>
              <TD className="text-center">{zscoreStatics.hfa_normal}</TD>
              <TD className="text-center">{zscoreStatics.sam}</TD>
              <TD className="text-center">{zscoreStatics.mam}</TD>
              <TD className="text-center">{zscoreStatics.wfh_mild}</TD>
              <TD className="text-center">{zscoreStatics.wfh_normal}</TD>
            </TR>

            <TR>
              <TD className="text-center">% of cases</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.suw, zscoreStatics.wfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.muw, zscoreStatics.wfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.wfa_mild, zscoreStatics.wfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.wfa_normal, zscoreStatics.wfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.ss, zscoreStatics.hfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.ms, zscoreStatics.hfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.hfa_mild, zscoreStatics.hfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.hfa_normal, zscoreStatics.hfa_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.sam, zscoreStatics.wfh_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.mam, zscoreStatics.wfh_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.wfh_mild, zscoreStatics.wfh_total)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.wfh_normal, zscoreStatics.wfh_total)}</TD>
            </TR>
          </TBody>
        </Table>
      </div>
      <small className="lgrey">* Based on most recent growth measurement in the last 30 days.</small>
    </div>
  )
}

export default KPICharts;

/*
<div id="table1">
          <div className="bg-white p20 brd-10 m-b-30">
            <div className="row">
              <div className="col-md-6">
                <div className="card-title card-title-gray flex aligncenter p-b-15 p20 w-full">
                  <h4 className="fw700 f18 text-left">
                    Select Analysis
                  </h4>
                </div>
                <div className="card-body flex aligncenter justifycenter coldir p20">
                  <div className="col-xs-12">
                      
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-title-gray justspacebetween p20">
                  <h4 className="fw700 f18 text-left">
                    Data Range
                  </h4>
                </div>
                <div className="card-body p20 clearfix">
                  <div className="col-xs-4">
                    <div className="m-r-5">
                      <DateTimePicker name="start_date" onChange={onFilterChanged} 
                        dateFormat="YYYY-MM-DD" defaultValue={filter.start_date}
                        className="form-input form-input-gray form-input-full"
                        placeholder="Start Date"/>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="m-r-5">
                        <DateTimePicker name="end_date" onChange={onFilterChanged} 
                          dateFormat="YYYY-MM-DD" defaultValue={filter.end_date}
                          className="form-input form-input-gray form-input-full"
                          placeholder="End Date"/>
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="m-r-5">
                        <select onChange={e => {setLoading(true);onFilterChanged('data_range', e.target.value);}} 
                          defaultValue={reportType}
                          className="form-select-dropdown w-full form-select-w-100-xs m-b-10-xs">
                          <option value="all">Preset date filters</option>
                          <OptionsObjList list={PresetDateRange} id_key="sid" label_key="label"/>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 m-t-5">
                  <div className="card-title-gray aligncenter p20 w-full">
                    <h4 className="fw700 f18 text-left">
                      Chart Filter
                    </h4>
                  </div>
                  <div className="card-body p20">
                    <div className="clearfix">
                      <TDInputMultiSelect id="data_filter" label="" 
                        handleChange={onDataFilterChanged} idx={0} 
                        options={MaternalPregnancyFilter} screen={{}}
                        column={{client_id:'data_filter_types'}} 
                        rowData={filter} valueKey="sid"/>  
                    </div>
                  </div>
                </div>
              </div>        
            </div>
*/