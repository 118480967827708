import React, {Fragment, useState, useEffect, useContext, useRef} from 'react'
import ReactTooltip from 'react-tooltip'
import { useHistory, useLocation } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import env from "../../env"
import {Link} from "react-router-dom"
import CheckAccess from "../Roles/CheckAccess"
import {usePageTour} from "../../components/Common/usePageTour"
import useStyle from '../../hooks/useStyle'
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider"
import {usePageLocale} from "../../hooks/useLocale"
import {useSearch} from '../../hooks/useSearch'
import {SideMenuRoute} from "../Routes/Routing"
import { useCurrentUserHook } from '../Authentication/useUserHook'
/*const WAIT_INTERVAL = 1000;
let timer = null;
let searchKeyword = null;*/

const Header = (props) => {

  const {currentUser} = useCurrentUserHook()
  //const {localeState:{header}, localeDispatch} = useContext(LocalizeContext)

  const history = useHistory()
  const searchInputEl = useRef(null);
  // const [headerItems, setHeaderItems] = useState(header?.options || {})
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'header')
  const {searchButton, searchClick, setSearchButton, clearSearch, onSearchCallback, searchKeyword} = useSearch(props.setSearchString, null, searchInputEl)

  /*const [scroll, setScroll] = useState(0)      
  const [isTop, setIsTop] = useState(false) */
  let tour = usePageTour()

  /*${env.file_url+currentUser.profile_image_url}*/
  const profileImage = currentUser.profile_image_url?env.file_url+currentUser.profile_image_url:'../images/user-demo-icon.png'

  useEffect(() => {
    ReactTooltip.hide()
    setSearchButton(false)
    if(props.queryParam.search){
      props.setSearchString(props.queryParam.search)   
      setSearchButton(true)     
    }else{
      clearSearch()
    }
    
  }, [history.location.pathname])

  useEffect(() => {
    /*timer = null
    searchKeyword = null*/

    /*let unlisten = history.listen((location, action) => {
      if(currentpage !== location.pathname){
        ReactTooltip.hide()
        //clearSearch()
        setSearchButton(false)
      }
    })*/

    //Sep 1
    /*setTimeout(function () {
      tour && tour.init()
    }, 1000)*/

    /*return () => {
      unlisten()
    }*/
  }, []);

  useEffect(()=>{
    let currentUrlParams = new URLSearchParams(window.location.search);
    if(props.searchString !== currentUrlParams.get('search')){
      if(props.searchString){
        currentUrlParams.set('search', props.searchString);
      }else{
        currentUrlParams.delete('search')
      }
      props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }
  }, [props.searchString])

  /*const handleChange=(event)=>{
    clearTimeout(timer)
    searchKeyword = event.target.value
    timer = setTimeout(triggerChange, WAIT_INTERVAL)
  }

  const triggerChange = () => {
    props.setSearchString(searchKeyword)
  }*/

  const openFeedback =(e) =>{
    props.history.push(`/feedback?url=${props.location.pathname}`)
  }

  const routeHomePage = () =>{
    SideMenuRoute(history, currentUser.current_member?.home_page, currentUser)
  }

  /*useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setIsTop(scrollCheck)
        setScroll(scrollCheck)
      }
    })
  },[])*/

  return (
    <>
      <div id="ct" className="header-container">
        <header className="top-bar">
          <div className="flex wrap justspacebetween aligncenter top-bar-content">
            <div className="flex aligncenter gap-5">
              <span className="logo-bg flex justifycenter aligncenter pointer">
                <span onClick={()=>routeHomePage()} data-tip={labelLocale('home_screen')}>
                  <img id="header_home" className="w-55 w-40-xs"
                    src="images/logo/cuetree_Icon.png" alt="cueTree Logo" />
                </span>
              </span>
              <span className="flex justifycenter aligncenter m-l-10 text-white f18"
                data-tip={labelLocale('back_page')}
                onClick={() => history.goBack()}>
                  <i className="fa fa-arrow-left" />
              </span>
            </div>
            <div className="flex aligncenter pointer">
              <ul className="flex aligncenter menu-xs">
                <li data-tip={labelLocale('search')}>
                  <a id="search_btn" onClick={e => searchClick()} 
                    className="flex m-r-30 m-r-22-xs pos-rel">
                    <i className="fas far fa-search white font-14"/>
                  </a>
                </li>
                <li data-tip={(currentUser?.current_organization?.data?.name || '')}>
                  <a onClick={(e) => props.toggleOrgModal()} className="flex m-r-30 m-r-22-xs">
                    <img src="images/web-icon.png" alt="" />
                  </a>
                </li>
                <li data-tip={labelLocale('notification')}>
                  <Link to="/notifications" className="flex m-r-30 m-r-22-xs pos-rel">
                    <img src="images/notification-icon.png" alt="" />
                    <span className="notification-count-header tcenter f10 brd-100p flex aligncenter justifycenter fw900">
                      0
                    </span>
                  </Link>
                </li>
                <li data-tip={labelLocale('preference')}>
                  <Link to="/user/profile" 
                    className="flex aligncenter m-r-30 m-r-22-xs user-dropdown-xs">
                      <img className="user-dropdown flex" src={profileImage}/>
                  </Link>
                </li>
                <li data-tip={labelLocale('main_menu')} onClick={()=> props.setOpenSideNav(!props.openSideNav)}>
                  <div className="flex m-r-30 m-r-5-xs">
                    <img src="images/menu-icon.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
      
      {searchButton && 
        <div className="search-box flex search-color bg-white shadow-tiny m-b-10">
          <div className="flex-grow">
            <i className="fa fa-search"/>
            <input type="text" className="f18 f16-xss"
              ref={searchInputEl}
              placeholder="Search here.." 
              defaultValue={props.searchString || ''}
              autoFocus onChange={(e)=>onSearchCallback(e)}/>
          </div>
          <div>
            <i className="fas fa-times search-close" onClick={()=>clearSearch()}/>
          </div> 
        </div>
      }
    </>
  )

}

export default Header;