import React, {useState, useEffect, useRef} from 'react';
import {CueTip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import moment from '../../../../node_modules/moment/min/moment.min.js'
import "react-datepicker/dist/react-datepicker.css";
import EditDataSourceIcon from '../EditDataSourceIcon';
import {FieldLabel, Description, RequiredMsg, FieldReview, FieldPoint} from '../FieldLabel';
import DatePicker from "react-datepicker";
import Cs from '../../../services/CommonService';
// import Calendar Component 
//import DatePicker from 'react-date-picker'

const InputDateTimePicker = ({renderField, field, formData, readOnly, errors, formFn, isFormWizard, openFieldLabelModal, currentLocale, position, fieldReview, formCancelAlertLocale}) => {
  let dateFormat = "YYYY-MM-DD";
  if(field.component === 'timepicker')dateFormat = "YYYY-MM-DD HH:mm";
  
  let d = null;
  if(formData[field.client_id+'_formatted_date']){
    d = moment(formData[field.client_id+'_formatted_date']).toDate();
  }

  const [value, setDate] = useState(d);
  const [showError, setShowError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  }

  useEffect(() => { 
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    }
  }, [])
  
  if(!renderField){
    return null;
  }

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholderText': field.placeholder,
    'className':'form-input form-input-gray form-input-full',
    'showTimeSelect': (field.component === 'timepicker' && value) ? true : false,
    'timeIntervals': (field.component === 'timepicker' && value) ? 15: null
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {'required': true};
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  const onDateChanged = (value) =>{
    const dateStr = moment(value).format(field.dateFormat);
    /*if(FormHelper.confirmValue(field.reconfirm_value, dateStr, formCancelAlertLocale)){*/
    setIsOpen(false);
    formData[field.client_id+'_formatted_date'] = moment(value).format(dateFormat);
    formData[field.client_id] = dateStr;
    setDate(value);
    formFn['on_select_'+field.client_id] && formFn['on_select_'+field.client_id](value, formData[field.client_id+'_formatted_date']);
    onDateSelect();
    setError();
    /*}*/
  }

  const onTimeChanged = (value) =>{
    const dateStr = moment(value).format("HH:mm");
    formData[field.client_id] = dateStr;
    const splits = dateStr?.split(":");
    formData[field.client_id+'obj'] = {
      hr:splits[0],
      min:splits[1]
    };
    setDate(value);
    setError();
  }

  formFn["update_"+field.client_id] = (date) => {
    const d = moment(date).toDate();
    onDateChanged(d);
  };

  //Common utility functions
  const getDate = (dateKey) =>{
    return FormHelper.getValueFormKeys(dateKey+'_formatted_date', formData, formFn.foreignData);
  }

  const calcDateDiff = (startDateKey, endDateKey, diff) =>{
    const startDate = getDate(startDateKey);
    const endDate = getDate(endDateKey);
    return Cs.compareDate(endDate, startDate, diff);  
    //updateByModalName(targetKey, diffVal); //add to form builder
  }

  const add = (targetKey, datekey, no, dwmy) =>{
    const date = getDate(datekey, formData, formFn.foreignData);
    const futureDate = moment(date).add(no, dwmy).format(dateFormat);
    //formFn["update_"+targetKey](futureDate);
    updateByModalName(targetKey, futureDate); 
  }

  const subtract = (targetKey, datekey, no, dwmy) => {
    const date = getDate(datekey, formData, formFn.foreignData);
    const pastDate = moment(date).subtract(no, dwmy).format(dateFormat);
    //formFn["update_"+targetKey](pastDate);
    updateByModalName(targetKey, pastDate); 
  }

  const updateByModalName = (targetKey, value) =>{
    formFn["update_"+targetKey](value);
  }
  //

  const onDateSelect = () =>{
    try{
      if(field.function_expression){
        eval(field.function_expression);
      }
    }catch(e){
      console.error(e.message)
    }
  }

  //FormHelper.setDisabled(field, inputAttributes);
  FormHelper.setDatePicker(field, inputAttributes);
  if(isOpen){
    formFn['set_min_'+field.client_id] && formFn['set_min_'+field.client_id](inputAttributes);
    formFn['set_max_'+field.client_id] && formFn['set_max_'+field.client_id](inputAttributes);
  }
  //FormHelper.checkFieldValidity(field, formData, errors);

  try{
    const setMinDate = (srcKey, dwmy, no) =>{
      inputAttributes.minDate = Cs.addDwmyToDate(formData[srcKey+'_formatted_date'], dwmy, no, null, true);
    }

    const setMaxDate = (srcKey, dwmy, no) =>{
      inputAttributes.maxDate = Cs.addDwmyToDate(formData[srcKey+'_formatted_date'], dwmy, no, null, true);
    }
  
    if(isOpen && field.init_expression){
      eval(field.init_expression);
    }

    if(formFn["filter_"+field.client_id]){
      inputAttributes.filterTime = (time) => formFn["filter_"+field.client_id](time);
    }

  }catch(e){
    console.error(e.message)
  }

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id] ==null?true:false;
      errors[name].invalid = onValidateExp(formData);
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name];
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }

  const onValidateExp = (d) =>{
    try{
      if(field.validate_expression){
        return eval(field.validate_expression);
      }
      return errors[field.client_id].invalid;
    }catch(e){
      console.error(e.message);
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>
            <span className="fas fa-exclamation-triangle m-r-5"/>
            <RequiredMsg {...{field, currentLocale}}/>
          </div>
        </div>
      )
    }
    return null;
  }

  return (
    <div ref={ref} {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
        <FieldLabel field={field} 
          isFormWizard={isFormWizard}
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        {field.is_time_only ?
          <DatePicker selected={value}
            className="form-input form-input-gray form-input-full"
            onChange={(date) => onTimeChanged(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"/>
          :
          <>
            <input className='form-input form-input-gray form-input-full' 
              disabled={readOnly} value={formData[field.client_id] ? formData[field.client_id]:''} 
              onClick={handleClick} />
            {isOpen ?
              <DatePicker dateFormat={dateFormat} selected={value} 
                onChange={date => onDateChanged(date)} dropdownMode="select"
                peekNextMonth showMonthDropdown showYearDropdown  
                {...inputAttributes} shouldCloseOnSelect={true}
                inline/>
              : null
            }
          </>
        }
        <FieldPoint userRefId={formFn.userRefId} clientId={field.client_id} 
          point={field.point} currentLocale={currentLocale} 
          negPoint={field.incorrect_point}/>
        <Description description={field.description} 
          currentLocale={currentLocale}
          locale={field.locale?.description}/>
        <FieldReview review={fieldReview}/>
        <Error/>
      </div>
    </div> 
  )
 
}

export default InputDateTimePicker;