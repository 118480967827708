import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { JourneyInlineMenu, PortalToggleMenu } from "../GenericPortalPage/Common";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../services/CommonService";
import PatientAdditionalInfoForm from "./Form/PatientAdditionalInfoForm";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import {NotesBox} from "../GenericPortalPage/NotesBox";
import UsersRolesPopup from "../../DataAccesses/UsersRolesPopup";
import PatientAdditionalInfoService from "./PatientAdditionalInfoService";
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import {FormFieldCard, RenderData} from "../../Common/FormFieldsView"
import ReactTooltip from "react-tooltip";
import { CTTooltip } from "../../Common/CueTooltip";
import {EmptyRowAlert} from '../../Common/TableView';
import FormTemplateList from "../../../constants/FormTemplateList";
import {FontAwesomeBtn} from "../../Common/Button";
import {usePageLocale} from "../../../hooks/useLocale";
import { useCurrentUserHook } from "../../Authentication/useUserHook";

let formProps = null;
let requestParams = null;
let formFields = [];
const PatientAdditionalInfoView = (props) => {

  let history = useHistory()
  const {currentUser} = useCurrentUserHook()
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')

  let { journey, child, formId, componentName, menuOptions, orgBgImageStyle, dataSource } = props
  
  const [additionalInfo, setAdditionalInfo] = useState({})
  const [loading, setLoading] = useState(false)

  const { isOpen: isInfantActivityFormOpen, toggleModal: toggleInfantActivityModal} = useModal()
  const { isOpen: isNotesOpen, toggleModal: toggleNotesModal } = useModal()
  const { isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const isMultiChildCase = journey.associated_journey_id && FormTemplateList.mcjMotherForms.indexOf(formId) > -1

  const routeActivityForm = (path, id) => {
    formProps = Cs.routeInfantPath(path, {}, journey, history, null, id);
    formProps.component = PatientAdditionalInfoForm;
    toggleInfantActivityModal();
  }

  const onFormSubmit = (additionalInfo) => {
    setAdditionalInfo(additionalInfo);
    toggleInfantActivityModal();
  }

  useEffect(() => {
    requestParams = {
      sort_column: "updated_at",
      sort_direction: "desc",
      per_page: 50,
    };

    getAdditionalInfo();
  }, [dataSource.child_form, componentName]);

  let getAdditionalInfo = () => {
    setLoading(true)
    requestParams.form_id = dataSource.child_form;
    requestParams.form_fields_params = {
      'ids':[dataSource.child_form]
    }
    requestParams.journey_profile_id = journey.id;

    PatientAdditionalInfoService.getAll(requestParams).then(({status, data}) => {
      if (status == 200) {
        formFields = data.form_fields[dataSource.child_form] || []
        setAdditionalInfo(data?.additional_info_list[0] || {});
        setLoading(false)
      }
    })
  }

  if(loading)
    return <div className="spinner"/>

  const AdditionalInfo = () => {
    return (
      <>
        <div className="font-14">
          <span className="bold"> Last Updated</span>
            {Cs.formatUpdateDate(additionalInfo?.updated_at)}
            <span data-tip="Edit additional household info" className="m-l-15 display-inline white"
              onClick={(e) => routeActivityForm(dataSource, additionalInfo.id)}>
            <i className="fas fa-edit lred" />
          </span>
        </div>

        <div className="table card-table p-5">
          <FormFieldCard formFields={formFields} 
            data={additionalInfo.data} 
            dataSource={additionalInfo.data_source}/>
        </div>   
      </>
    ) 
  }
  
  return (
    <>
      <div className="flex coldir fulwid p10">
        <div className="flex alignstart justspacebetween">
          <div className="h180-scroll">
            {additionalInfo.id?
              <AdditionalInfo {...props}/>
              :
              <div className="f16 wk-center">
                <EmptyRowAlert/>
                <FontAwesomeBtn id="add" tooltip="Add details" 
                  iconCss="fa fas fa-plus" isResponsive={false} name={labelLocale(3)}
                  css="m-t-5" colorCss="btn-green"
                  onSelect={()=>routeActivityForm(dataSource, additionalInfo.id)}>
                </FontAwesomeBtn>
              </div>
            }
          </div>

          <div className="flex aligncenter">
            <span data-tip="Add/Edit details"
              className="icon-gray-cirle brd-30 flex aligncenter justifycenter" 
              onClick={() => routeActivityForm(dataSource, additionalInfo.id)}>
                <i className={additionalInfo.id?'fas fa-edit':'fas fa-plus'} />
            </span>

            <PortalToggleMenu
              journey={journey} 
              menuOptions={menuOptions} 
              journeyType="infant_journey"
              menuDsId="3530"
              permissionKey="infant_portal"/>
          </div>
        </div>
      </div>
      
      {isInfantActivityFormOpen && (
        <GenericModal
          toggleModal={toggleInfantActivityModal}
          isPopupMode="true"
          onFormSubmit={onFormSubmit}
          closeConfirmPopup={true} {...formProps}
        />
      )}
      
    </>
  )
}

export default PatientAdditionalInfoView;