import {useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";

const useCurrentUserHook = () => {
	const { state: {screen, user: currentUser}, dispatch, isAuthenticated, localeDispatch} = useContext(AuthContext);

	const hasDepartments = () => {
		return currentUser.current_organization.data_source?.organization?.label === "Government Unit";
	}

	const getCurrentOrgName = () => {
		return currentUser.current_organization.data?.name;
	}

	return{
		screen,
		currentUser,
		dispatch,
		isAuthenticated,
		localeDispatch,
		hasDepartments,
		getCurrentOrgName	
	}
}

export {useCurrentUserHook};