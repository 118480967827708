import React, { Fragment, useState, useEffect, useContext, useMemo} from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";
import useStyle from "../../../../../hooks/useStyle";
import Cs from "../../../../../services/CommonService";
import ActivityForm from '../Form/ActivityForm';
import useModal from "../../../../../hooks/useModal";
import GenericModal from "../../../../Modals/GenericModal";
import {useSubscriptionAccess} from '../../../../Subscriptions/useSubscription';
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import {getLocale} from '../../../../FormBuilder/FieldLabel';
import { useCurrentUserHook } from "../../../../Authentication/useUserHook";

let actFormProps = {};
const ActivityModalLinks = ({child, journeyProfile, schedule, scheduleId, autoOpenForm, onScheduledFormSubmit, onScheduledFormClose, toggleProcessTab}) => {
  const history = useHistory();
  const {currentUser} = useCurrentUserHook()
  const { isOpen:isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal();
  const {subscriptionAccesses:{pins_process_mcj}} = useSubscriptionAccess();

  useEffect(()=>{
    if(autoOpenForm){
      routeActivityForm({sid:schedule.scheduled_form_type, child_form:schedule.scheduled_form_id}, {visit_date:schedule.start})
    }
  }, [schedule])

  const formLabel = useMemo(()=>{
    return getLocale(pins_process_mcj.options.find(o => o.sid === schedule.scheduled_form_type), currentUser.current_locale)
  }, [currentUser.current_locale]) 

  const routeActivityForm = (schedule) =>{
    const path = pins_process_mcj.options.find(o => schedule.scheduled_form_type === o.sid);
    actFormProps = Cs.routeInfantPath(path, {}, journeyProfile, history, null);
    if(child){
      const c = child;
      c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date);
      actFormProps.location.state = {
        'child_fk_id': c.record_id, 
        'dob_baby_formatted_date': c.data.dob_baby_formatted_date, 
        'id_mother': journeyProfile.data.cell_number, 
        'baby_age': c.data.baby_age
      }
    }

    if(path.sid === 'growth_measurement'){
      const d = Cs.formatUpdateDate(schedule.start ,'YYYY-MM-DD');
      actFormProps.location.state.visit_date = d;
      actFormProps.location.state.visit_date_formatted_date = d; 
    }
    actFormProps.match.params.schedule_id = scheduleId;
    actFormProps.component = ActivityForm;
    toggleInfantActivityModal();
  }

  const onFormSubmit = (activity={}) =>{
    toggleInfantActivityModal();
    onScheduledFormSubmit && onScheduledFormSubmit(schedule);
  }

  const onFormClose = () =>{
    toggleInfantActivityModal();
    onScheduledFormClose && onScheduledFormClose(schedule);
  }

  return(
    <>
      {!autoOpenForm && journeyProfile &&
        <>
          {schedule.scheduled_form_type === 'growth_measurement'  &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>routeActivityForm(schedule)}>
              {formLabel}
            </div>
          }
          {schedule.scheduled_form_type === 'assess_bf' &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>routeActivityForm(schedule)}>
              {formLabel}
            </div>
          }
          {schedule.scheduled_form_type === 'prenatal_care' &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>routeActivityForm(schedule)}>
              {formLabel}
            </div>
          }
          {schedule.scheduled_form_type === 'immunization' &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>{toggleProcessTab && toggleProcessTab('immunization')}}>
              {formLabel}
            </div>
          }
        </>
      }

      {isInfantActivityFormOpen && 
        <GenericModal toggleModal={onFormClose} 
          isPopupMode="true" onFormSubmit={onFormSubmit} 
          closeConfirmPopup={true} {...actFormProps} />
      }
    </>
  )
}

export default ActivityModalLinks;