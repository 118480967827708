import React, {Fragment, useState, useEffect, useContext, useRef} from "react"
import {Link} from "react-router-dom"
import NutritionCareService from './NutritionCareService'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import Tabs, { TabPane } from 'rc-tabs'
import {CounselingPanelTab, MotherInfo} from "../HealthJourney/Counseling/List/Counseling"
import env from '../../env'
import {PlannerVideoPlayer} from '../Common/VideoPlayer'
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import useStyle from "../../hooks/useStyle"
import Cs from "../../services/CommonService"
import {LocaleLabel} from "../Common/FormInput"
import {PillDsList} from "../Common/NotificationPill"
import CommentsModal from '../Modals/CommentsModal'
import { FontAwesomeIcon2 } from "../Common/ImageIcons"
import CounselingService from "../HealthJourney/Counseling/CounselingService"
import LoadMoreBtn from '../Common/LoadMoreBtn'
import {CountryCodes} from "../../constants/CountryCodes"
import CheckAccess from "../Roles/CheckAccess"
import { DivRichTextView } from "../Common/RichTextView"
import { useCurrentUserHook } from "../Authentication/useUserHook"

// let requestParams = null
// let selectedItem = null
const NutritionCareNotification = (props) => {
  useStyle('/css/tile_components.css', true)
  let { current: scope } = useRef({ requestParams: null, selectedItem: null,});
  const {currentUser} = useCurrentUserHook()

  //const [nutritionCareList, setNutritionCareList] = useState([])
  //const [planners, setPlanners] = useState({});
  const [plannerList, setPlannerList] = useState([])
  const [notifications, setNotifications] = useState([])
  //const [formAlerts, setFormAlerts] = useState([])
  const [loading, setLoading] = useState(true)
  const [index, setIndex] = useState(null)
  const [isToggleOpen, setToggle] = useState(false)
  const [formFields, setFormFields] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})

  const { isOpen: isVideoPlayerOpen, toggleModal: toggleVideoPlayer } = useModal()
  const { isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()

  const isWhatsappShareEnabled = CheckAccess.checkOrgLevelAccess(currentUser, 'whatsapp_share')

  const setActiveIndex = (idx, is_item_viewed, notification_id, notification_type) => {
    const toggleIdx = (idx === index ? null : idx);
    if(toggleIdx!=null)getPlanners(idx);
    setIndex(toggleIdx);
    setToggle(toggleIdx?true:false);
    !is_item_viewed && updateReadStatus(notification_id, notification_type, idx);
  }

  useEffect(() => {
    if(props.counselingIds?.length > 0){
      scope.requestParams = {
        'counseling_ids':props.counselingIds,
      }
      getCounselingList()
    }else{
      scope.requestParams = {
        'locale_sid':props.localeSid,
        'page':currentpage,
        'per_page':10,
        'item_type': props.itemType,
        'item_uuid': props.itemUuid,
        'journey_profile_id':props.journeyProfileId,
        'parent_notification_id':props.parentNotificationId,
        'notification_id':props.notificationId
      }

      getNotificationList()
    }

    return () =>{
      scope.requestParams = null
      scope.selectedItem = null
    }
  }, [])

  /*useEffect(()=>{
    if(requestParams.page != currentpage){
      getNotificationList()
    }
  }, [currentpage])*/

  const getNotificationList = () => { 
    NutritionCareService.notifications(scope.requestParams).then((res)=>{
      if(res.status === 200){
        setPagemeta(res.data.meta)
        setPlannerList(res.data.planner_list || [])
        setNotifications(res.data.notifications || [])
        //setFormAlerts(res.data.form_alerts || [])
        setFormFields(Cs.formFieldListToObj(res.data.form_fields))
        setLoading(false)
      }
    })
  }

  const getCounselingList = () => { 
    CounselingService.notifications(scope.requestParams).then((res)=>{
      if(res.status === 200){
        setPlannerList(res.data.planner_list || [])
        setNotifications(res.data.counselings || [])
        setFormFields(Cs.formFieldListToObj(res.data.form_fields))
        setLoading(false)
      }
    })
  }

  const updateReadStatus = (notification_id, notification_type, idx) =>{
    if(notification_id){
      NutritionCareService.updateEventDrivenNotification({
        id:notification_id,
        is_item_viewed:true
      }).then(({status})=>{
        if(status === 204){
          if(notification_type === 'planner'){
            plannerList[idx].is_item_viewed = true
            setPlannerList([...plannerList])
          }else if(notification_type === 'counseling'){
            notifications[idx].is_item_viewed = true
            setNotifications([...notifications])
          }else if(notification_type === 'nutrition_care'){
            notifications[idx].is_item_viewed = true
            setNotifications([...notifications])
          }
        }
      })
    }
  }

  const getPlanners = (idx) => { 
    const n = notifications[idx]
    if(n.item_type && n.item_uuid){
      setLoading(true)
      NutritionCareService.planners({
        'item_type':n.item_type,
        'item_uuid':n.item_uuid
      }).then((res)=>{
        if(res.status === 200){
          setPlannerList(res.data || [])
          setLoading(false)
        }
      })
    }
  }

  const openVideo = (planners, step_planners, planner_type, current_index) =>{
    scope.selectedItem = {startIndex:current_index}
    if(planner_type === 'step_planner'){
      scope.selectedItem.stepPlannerList = Object.assign([], step_planners);
     /* const params = new URL(planner.data.url).searchParams
      planner.data.video_id = params.get('v')
      const startSec = Cs.getSeconds(planner.data.start_time)
      const endSec = Cs.getSeconds(planner.data.end_time)
      selectedItem.data.video_url = `${planner.data.video_url}?start=${startSec}&end=${endSec}`*/
    }else{
      scope.selectedItem.plannerList = Object.assign([], planners);
    }
    toggleVideoPlayer()
  }

  const addComment = (e, item) => {
    e.stopPropagation()
    scope.selectedItem = {
      id: item.notification_id,
    }
    toggleCommentModal()
  }

  /*const suggestedVideos = (videos_suggested=[]) => videos_suggested.map((v, i) => {
    if(v.data?.reference_url){
      const params = new URL(v.data.reference_url).searchParams
      v.data.video_url = `${env.youtube_url}${params.get('v')}`
    }
    return (
      <a key={i} onClick={()=>updateReadStatus(v.notification_id)} className="font-18 p-10" href={planners[v.planner_fk_id]?.data?.reference_url} target="_blank">
        {planners[v.planner_fk_id]?.data?.name}
      </a>
    )
  })*/

  /*
<FontAwesomeIcon2 parentCss="p-t-6 pull-right bg-lgrey" 
                onSelect={e=>addComment(e, item)}
                iconCss="fas fa-comment-dots white"/>

  const CounselingPanels = ({item, i}) => {
    return(
      <div onClick={()=>updateReadStatus(item.notification_id, 'counseling', i)} className="col-xs-12 col-md-6" key={i}>
         <div className="bg-white brd-10 card-shadow m-b-15" key={i} onClick={()=>{!item.is_item_viewed && updateReadStatus(item.notification_id, 'counseling', i)}}>
            <div className="card-title card-title-gray flex p-b-15 p20 coldir">
              <h4 className="fw700 f18 card-heading flex coldir text-left">
                {item.data_source?.stage?.label}
                {item.is_item_viewed && <span className="fas fa-check-circle font-14 pull-right"/>}
              </h4>
            </div>
            <div className="card-content p20">
              <CounselingPanelTab data={item.data} key={i}/>
            </div>
        </div>
      </div>
    )
  }*/

  /*const formAlertListView =  formAlerts.map((item, i) => (
    <div className="p-5 bg-ltint" key={i}>
      <p className="font-16">
        {i+1}) {item.message}
      </p>
    </div>
  ))*/

  if(isCommentOpen){
    return <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={scope.selectedItem} type="notification"/>
  }

  const sendToWhatsApp = (item) =>{
    const f = props.countryCode?CountryCodes[props.countryCode.toLowerCase()]:null
    if(f?.[2]){
      const u = window.encodeURI(`https://${window.location.hostname}/public/notifications/${props.journeyProfileId}/${currentUser.current_locale}`)
      window.open(`https://wa.me/${f?.[2]}${props.mobileNo}?text=${u}`);
    }
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="clearfix">
          {loading ? 
            <div className="spinner"/>
            :
            <>
              <div className="flex justspacebetween modal-title p-b-10">
                <h4 className="f18 text-white fw500 p12-10-15">
                  Read/watch the mentoring items shown below to improve the nutrition intake
                </h4>
              </div>
              <div className="m-t-10 p-l-10 p-r-10 vh-80 scroll-y">
                <Notifications 
                  notifications={notifications} 
                  isToggleOpen={isToggleOpen} 
                  plannerList={plannerList}
                  index={index} 
                  setActiveIndex={setActiveIndex}
                  currentUser={currentUser}
                  openVideo={openVideo}
                  formFields={formFields}
                  updateReadStatus={updateReadStatus}
                  isVideoPlayerPopupOpen={isVideoPlayerOpen}
                  journeyProfileId={props.journeyProfileId}
                  sendToWhatsApp={sendToWhatsApp}
                  isWhatsappShareEnabled={isWhatsappShareEnabled}
                  mobileNo={props.mobileNo}/>
              </div>
              {/*<LoadMoreBtn currentPage={currentpage}
                totalItems={pagemeta.total}
                itemsPerPage={pagemeta.per_page}
                onPageChange={setCurrentpage}/>*/}
            </>
          }
        </div>
        {isVideoPlayerOpen && 
          <GenericModal 
            component={PlannerVideoPlayer}
            isOpen={isVideoPlayerOpen} 
            toggleModal={toggleVideoPlayer}
            title="Video Player"
            plannerList={scope.selectedItem.plannerList}
            stepPlannerList={scope.selectedItem.stepPlannerList} 
            startIndex={scope.selectedItem.startIndex}
            openInNewTab={true}/>
        }
      </div>
    </Fragment>
  )

}

const Notifications = (props) => props.notifications.map((n, i) => {
  if (n.counseling_form_id) {
    const formFields = props.formFields[n.counseling_form_id]
    return <CounselingMotherInfoList item={n} key={i} i={i} formFields={formFields} {...props}/>
  }
  return <NutritionCareCardView item={n} key={i} i={i} {...props}/>
})
//notifications=[], currentUser, index, setIndex, isToggleOpen, plannerList

const CounselingMotherInfoList = (props) => {
    const {item, i, index, plannerList, setActiveIndex, isToggleOpen, currentUser, updateReadStatus, isVideoPlayerPopupOpen, formFields, sendToWhatsApp, isWhatsappShareEnabled} = props;
    const isOpen = i === index;
    const [activePlanner, setActivePlanner] = useState();
    const ds = item.data_source || {};

    useEffect(()=>{
      if(isOpen){
        try{
          let defaultPlanner;
          if(isOpen && item.data?.planners_array?.length > 0){
            const planner = item.data?.planners_array[0];
            defaultPlanner = plannerList.find(p => p.id === planner.planner_fk_id && (p.notification_data?p.notification_data.counseling_id === item.id:true));
            openVideo([defaultPlanner], defaultPlanner?.planner_steps, null, 0);
            /*TODO-remove after counseling id added to notification data*/
            /*p.notification_data will not be in counselings with params counseling_ids*/
            /*if(defaultPlanner.length > 1){
              defaultPlanner = defaultPlanner.find((p)=> p.notification_data.counseling_id === item.id)
            }

            if(defaultPlanner.length === 1){
              defaultPlanner = defaultPlanner[0]
            }*/
          } 
        }catch(e){
          console.error(e.message)
        }
      }
    }, [isOpen])

    const openVideo = (planners, step_planners, planner_type, current_index) =>{
      let selectedItem = {startIndex:current_index}
      if(planner_type === 'step_planner'){
        selectedItem.stepPlannerList = Object.assign([], step_planners);
        selectedItem.autoPlay = true
      }else{
        selectedItem.plannerList = Object.assign([], planners);
      }
      setActivePlanner({...selectedItem})
    }

    if(isOpen === false && isToggleOpen)
      return null;

    return(
      <div className="col-xs-12" key={i}>
        <div className="card-shadow m-b-15">
          <div className="card-title flex p-t-10 p-b-10 p-l-10 p-r-10 coldir rgap-5"
            onClick={()=>setActiveIndex(i, item.is_item_viewed, item.notification_id, 'counseling')}>
            <h4 className={`${isOpen?'fw400':'fw700'} f15 card-heading flex coldir text-left`}>
              {ds.step ?
                <LocaleLabel dataSource={ds.step} currentLocale={currentUser.current_locale}/> 
                :
                <LocaleLabel dataSource={ds.stage} currentLocale={currentUser.current_locale}/> 
              }
            </h4>
            <div className="f14 fw500">
              <LocaleLabel dataSource={ds.stage} currentLocale={currentUser.current_locale}/> 
              {item.is_item_viewed &&
                <span className="fas fa-check-circle m-l-15 green"/>
              }
              <span className="m-l-15">{Cs.formatUpdateDate(item.notification_created_at)}</span>

              <FontAwesomeIcon2 parentCss="m-l-15 p-t-6 pull-right bg-lgrey font-20" 
                iconCss={`white font-18 fas fa-chevron-${isOpen?'down':'right'}`}/>

              {(isWhatsappShareEnabled && props.mobileNo) ?
                <FontAwesomeIcon2 parentCss="font-20 m-l-15 p-t-6 pull-right bg-lgrey" 
                  onSelect={()=>sendToWhatsApp(item)}
                  iconCss="white font-18 fab fa-whatsapp"/>
                :null
              }
            </div>
          </div>
          {isOpen &&
            <div className={`card-content p-l-10 p-r-10 ${isOpen ? 'ani-zoom' :''}`}>
              {activePlanner ?
                <PlannerVideoPlayer 
                  playInline={true}
                  {...activePlanner}/>
                :null
              }
              <div className="b-tab-table active">
                <div className="card-content p-t-20 p-b-20">
                  <CounselingPanelTab key={i}
                    data={item.data} 
                    formFields={formFields[item.counseling_form_id]} 
                    currentLocale={currentUser.current_locale} />
                </div>
                {plannerList?.length > 0 &&
                  <PlannerListView 
                    videos={item.data?.planners_array} 
                    parentCss="col-xs-12"
                    plannerList={plannerList} 
                    openVideo={openVideo} 
                    updateReadStatus={updateReadStatus}/>
                }
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

  const PlannerStepListView = ({plannerSteps=[], planner, openVideo, updateReadStatus}) =>  plannerSteps.map((item, i) => {
    return(
      <div onClick={()=>{openVideo(null, plannerSteps, 'step_planner', i);!item.is_item_viewed && updateReadStatus(planner.notification_id);}} 
        className="br-btm-grey miw-200" key={i}>
        <div className="flex coldir gap-10 aligncenter bold-500 op-09 black-light-1 p-5">
          <span className="fab fa-youtube f40 yt-red"/> 
          <div>
            {item.data.start_time} - {item.data.description}
            {item.is_item_viewed && <span className="fas fa-check-circle font-14 m-l-15"/>}
          </div>
        </div>
      </div>
    )
  })

  const PlannerListView = ({videos=[], parentCss, plannerList, openVideo, updateReadStatus}) => videos.map((v, i) => {
    /*i===0 First video already loaded*/
    if(i===0 || plannerList.length==0){
      return null
    }
    
    let planner = plannerList.find(p => p.id === v.planner_fk_id)
    if(!planner){
      return null
    }
    else if(planner.data.reference_url){
      const params = new URL(planner.data.reference_url).searchParams
      planner.data.img_url = `https://img.youtube.com/vi/${params.get('v')}/mqdefault.jpg`
    }

    return(
      <div className="miw-200 m-r-5" key={i}>
        <div className="bg-white brd-10 card-shadow m-b-15">
          <div className="card-title card-title-gray white flex p-b-15 p10 coldir">
            <h4 className="fw700 f14 card-heading flex coldir text-left">
              {planner.data.name}  
            </h4>
            {planner.is_item_viewed && 
              <div className="f14 m-t-10  fw500">
                <span className="fas fa-check-circle font-14 pull-right"/>
              </div>
            }
          </div>
          <div className="card-content flex p5 pointer scroll-auto">
            {planner.planner_steps?.length > 0 ? 
              <PlannerStepListView key={i} 
                plannerSteps={planner.planner_steps} 
                planner={planner}
                openVideo={openVideo} 
                updateReadStatus={updateReadStatus}/>
              :
              <div className="border-dashed-sm play-btn p5"
                onClick={()=>{openVideo([planner], null, 'planner', 0);!planner.is_item_viewed && updateReadStatus(planner.notification_id, 'planner', i);}}>
                <img src={planner.data.img_url} className="img-thumbnail img-rounded img-responsive center-block"/>
              </div>
            }
          </div>
        </div>
      </div>
    )
  })

  const NutritionCareAlerts = ({alerts=[]}) => alerts ? alerts.map((alert, i) => (
    <Fragment key={i}>
      <div>{alert.data.title}</div>
      <DivRichTextView className="form-details m-t-10" html={alert.data.details}></DivRichTextView>
    </Fragment>
  )):null

  const NutritionCarePlanners = ({alerts=[], plannerList, openVideo, updateReadStatus, setActivePlanner}) => alerts ? alerts.map((alert, i)=>{
    return(
      <Fragment key={i}>
        {alert.data?.videos_suggested_array?.length > 0 &&
          <div className="flex scroll-auto">
            <PlannerListView 
              videos={alert.data?.videos_suggested_array} 
              parentCss="col-xs-12"
              plannerList={plannerList} 
              openVideo={openVideo} 
              updateReadStatus={updateReadStatus}/>
          </div>
        }
      </Fragment>
    )
  }):null

  const NutritionCareCardView = (props) => {
    const {item, i, index, plannerList, setActiveIndex, isToggleOpen, currentUser, updateReadStatus, isVideoPlayerPopupOpen, journeyProfileId, sendToWhatsApp, isWhatsappShareEnabled} = props
    const isOpen = (i === index)
    const [activePlanner, setActivePlanner] = useState()
    const {current:scope} = useRef({})

    useEffect(()=>{
      if(isOpen){
        try{
          /*Loading first video in first alert as default planner.*/
          scope.nca = item.nutrition_care_alerts[0] 
          const planner = scope.nca.data.videos_suggested_array[0]
          const defaultPlanner = plannerList.find((p)=> (p.id === planner.planner_fk_id && p.notification_data.nutrition_care_alert_id===scope.nca.id))
          openVideo([defaultPlanner], defaultPlanner?.planner_steps, null, 0)
        }catch(e){
          console.error(e.message)
        }
      }
    }, [isOpen])

    const openVideo = (planners, step_planners, planner_type, current_index) =>{
      let selectedItem = {startIndex:current_index}
      if(planner_type === 'step_planner'){
        selectedItem.stepPlannerList = Object.assign([], step_planners);
        selectedItem.autoPlay = true
      }else{
        selectedItem.plannerList = Object.assign([], planners);
      }
      setActivePlanner({...selectedItem})
    }

    if(isOpen === false && isToggleOpen)
      return null

    return(
      <div className="col-xs-12" key={i}>
        <div className="card-shadow m-b-15">
          <div className="card-title flex p-t-10 p-b-10 p-l-10 p-r-10 coldir rgap-5"
            onClick={()=>setActiveIndex(i, item.is_item_viewed, item.notification_id, 'nutrition_care')}>
            <h4 className={`${isOpen?'fw400':'fw700'} f15 card-heading flex coldir text-left`}>
              {item.data.title}
            </h4>
            <div className="f14  color-gray2-dark fw500">
              {scope.nca && <PillDsList list={scope.nca.data_source.mcj_forms || []} dataKey="label"/>}
              {item.is_item_viewed &&
                <span className="fas fa-check-circle m-r-15 m-l-15 green"/>
              }
              {Cs.formatUpdateDate(item.notification_created_at)}

              <FontAwesomeIcon2 parentCss="m-l-15 p-t-6 pull-right bg-lgrey font-20"
               iconCss={`white font-18 fas fa-chevron-${isOpen?'down':'right'}`}/>
               
              {(isWhatsappShareEnabled && props.mobileNo) ?
                <FontAwesomeIcon2 parentCss="m-l-15 p-t-6 pull-right bg-lgrey" 
                onSelect={()=>sendToWhatsApp(item)}
                iconCss="white font-18 fab fa-whatsapp"/>
                :null
              }
            </div>
          </div>
          {isOpen &&
            <div className={`card-content p-l-10 p-r-10 ${isOpen ? 'ani-zoom' :''}`}>
              {activePlanner ?
                <PlannerVideoPlayer 
                  playInline={true}
                  plannerList={activePlanner.plannerList}
                  stepPlannerList={activePlanner.stepPlannerList} 
                  startIndex={activePlanner.startIndex}
                  autoPlay={activePlanner.autoplay}/>
                :null
              }
              <div className="b-tab-table active">
                {item.nutrition_care_alerts?.length > 0 &&
                  <NutritionCarePlanners 
                    alerts={item.nutrition_care_alerts}
                    plannerList={plannerList} 
                    openVideo={openVideo} 
                    updateReadStatus={updateReadStatus}/>
                }
                <div className="p-10 br-grey m-t-10">            
                  <NutritionCareAlerts alerts={item.nutrition_care_alerts}/>
                </div>
                <div className="lgrey font-12 p-5">
                  {/*76 users like you helped 234 mothers to help feed their babies better in last 7 days by 
                  showing these videos fully to the mother.You can join this special group of users.*/}
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }

export default NutritionCareNotification;