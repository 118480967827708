import React, {Fragment, useState, useEffect, useContext, useRef} from "react"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../../../hooks/useStyle"
import {tabMoreIcon} from "../../../Common/Button"
import Tabs, { TabPane } from "rc-tabs"
import KPICharts from "./KPICharts"
import Leaderboard from "./Leaderboard"
import useModal from '../../../../hooks/useModal'
import {CheckBox, DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import {GraphMetricsList, PresetDateRange, MaternalPregnancyFilter} from "../../../../constants/DataSourceList"
import CommonService from "../../../../services/CommonService"
import {SavedReportFilterList} from './SavedReportFilterList'
import SavedReportFilterService from './SavedReportFilterService'
import {OrgMemberCharts} from "../../../../constants/DataSourceList"
import {useMedicalFacilityApi} from '../../../Organizations/MedicalCareFacility/useMedicalFacilityApi'
import FormHelper from '../../../FormBuilder/FormHelpers'
import LocationService from "../../../../services/LocationService"
import { useCurrentUserHook } from "../../../Authentication/useUserHook"

let searchKeyword = null;
let timer = null;
const SEARCH_WAIT_INTERVAL = 1000;
const Filters = ({onFilterSubmit, chartFilter, setChartFilter, activeTab, ...props}) => {
  const {currentUser} = useCurrentUserHook()
  const currentOrgData = currentUser.current_organization?.data || {}
  
  const [loading, setLoading] = useState(false)
  const [talukas, setTalukas] = useState([])
  const [villages, setVillages] = useState([])
  const [facilities, setFacilities] = useState([])
  const [chartFilterGroup, setChartFilterGroup] = useState(CommonService.groupBy(MaternalPregnancyFilter, 'data_key'))
  const groupLabel = {
    'medical_facility':'Medical Facilities',
    'maternal_pregnancy':'Maternal/Pregnancy',
    'zscore_statics':'Z-Score Statics',
    'child_birth':'Birth Of Child',
    'growth_measurement':'Child Development'
  }

  const [filter, setFilter] = useState(chartFilter)
  const sideNavEl = useRef(null);
  
  /*let {
    districts:mfDistricts, talukas:mfTalukas,  
    getTalukas:mfGetTalukas, getMedicalFacilities:mfGetMedicalFacilities
  } = useMedicalFacilityApi(currentUser.current_organization_id)*/

  /*country: current_org_data.country,
      country_code: current_org_data.country_code,
      district: current_org_data.district,
      district_code: current_org_data.district_code,
      state: current_org_data.state,
      state_code: current_org_data.state_code,
      taluka: current_org_data.taluka,
      taluka_code: current_org_data.taluka_code*/

  const handleChange=(search, triggerChange)=>{
    clearTimeout(timer)
    searchKeyword = search
    timer = setTimeout(triggerChange, SEARCH_WAIT_INTERVAL)
  }

  const onTalukaChange = () =>{
    setLoading(true)
    LocationService.get_taluka({
      country_name:currentOrgData.country,
      state_name:currentOrgData.state,
      district_code:currentOrgData.district_code,
      search:searchKeyword
    }).then((res)=>{
      setLoading(false)
      setTalukas(res.data.sub_districts)  
    }) 
  }

  const onVillageChange = () =>{
    setLoading(true)
    LocationService.get_village({
      country_name:currentOrgData.country,
      state_name:currentOrgData.state, 
      district_name:currentOrgData.district, 
      sub_district_names:filter.talukas?.join(','),
      search:searchKeyword
    }).then((res)=>{
      setLoading(false)
      setVillages(res.data.villages)  
    })
  }

  const onFacilityChange = () =>{
    setLoading(true)
    let req = {
      search: searchKeyword,
      /*district: data.district,
      taluka: data.taluka*/
    }
    
    LocationService.getPublicHealthCenters(req).then((res)=>{
      setLoading(false)
      setFacilities(res.data.health_centers)
    })
  }

  const onFilterChanged = (name, value, date) =>{
    setFilter({...filter, [name]:value})
  }

  const toggleFilter = (value, name, is_add=true) => {
    if(is_add){
      if(name === 'districts'){
         value = value.district 
      }else if(name === 'talukas'){
        value = value.sub_district_name
      }else if(name === 'villages'){
        value = value.village_name
      }
    }
      
    filter[name] = filter[name] || []
    const idx = filter[name]?.indexOf(value);
    if(idx > -1) {
      filter[name] = filter[name].filter(i => i !== value)
    }else {
      filter[name].push(value)
    }
    setFilter({...filter})
  }

  const filterExists = (value, name) => {
    return filter[name]?.indexOf(value) > -1
  }

  const selectFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  return(
    <div id="ct"> 
  <div 
    id="menu-main" 
    ref={sideNavEl}
    className={`menu filter-box-right menu-box-detached ${props.openSideNav ? "menu-active" : "hidden"}`}
    style={{ width: '500px', zIndex: 400 }}
  >
    <div className="filter-box-scroll" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
      <div>
        {filter.report_type === 'geo_location' && 
          <MedicalFacilityFilters currentUser={currentUser} filter={filter} setFilter={setFilter}/>
        }
        
        <div className="card-title card-title-gray flex aligncenter p10 w-full">
          <h4 className="f18 text-left">Select Analysis

          </h4>
        </div>

      
        {/*<div className="card-body flex aligncenter justifycenter coldir p10">
          {loading && <div className="spinner"/>}
          <div className="col-xs-12 m-b-5">
            <h4 className="f18 text-left">
              Districts
            </h4>
            <SelectedFilterItems list={filter.districts} name="districts" toggleFilter={toggleFilter}/>
            <div className="m-t-5 m-b-10 mxh-300 scroll-auto">
              <CheckBox list={mfDistricts} 
                labelKey="district" valueKey="district" 
                toggle={toggleFilter} exists={filterExists} 
                onToggleReturnObject={true} name="districts"/>
            </div>
          </div>

          <div className="col-xs-12 m-b-5">
            <h4 className="f18 text-left">
              Talukas
            </h4>
            <SelectedFilterItems list={filter.talukas} name="talukas" toggleFilter={toggleFilter}/>
            <div className="m-t-5 m-b-10 mxh-300 scroll-auto">
              <CheckBox list={mfTalukas} 
                labelKey="taluka" valueKey="taluka" 
                toggle={toggleFilter} exists={filterExists} 
                onToggleReturnObject={true} name="talukas"/>
            </div>
          </div>
        </div>*/}

        <div className="card-body flex aligncenter justifycenter coldir p10">
          {loading && <div className="spinner"/>}
          <div className="col-xs-12 m-b-5">
            <input className="form-input form-input-gray form-input-full"
             placeholder="Select Talukas"
              onChange={e => handleChange(e.target.value, onTalukaChange)}
              />
            <SelectedFilterItems list={filter.talukas} name="talukas" toggleFilter={toggleFilter}/>
            <div className="m-t-5 m-b-10 mxh-300 scroll-auto">
              <CheckBox list={talukas} labelKey="sub_district_name" valueKey="sub_district_name" 
              toggle={toggleFilter} exists={filterExists} 
              onToggleReturnObject={true} name="talukas"/>
            </div>
          </div>
          <div className="col-xs-12 m-b-5">
            <input className="form-input form-input-gray form-input-full" 
            placeholder="Select Villages" 
            onChange={e => handleChange(e.target.value, onVillageChange)}
            />
            <SelectedFilterItems list={filter.villages} name="villages" toggleFilter={toggleFilter}/>
            <div className="m-t-5 m-b-10 mxh-300 scroll-auto">
              <CheckBox list={villages} 
              labelKey="village_name" valueKey="village_name" 
              toggle={toggleFilter} exists={filterExists} 
              onToggleReturnObject={true} name="villages"/>
            </div>
          </div>
          <div className="col-xs-12 m-b-5">
            <input className="form-input form-input-gray form-input-full"
             placeholder="Select Facilities"
              onChange={e => handleChange(e.target.value, onFacilityChange)}
              />
            <div className="m-t-5 m-b-10 mxh-300 scroll-auto">
              <CheckBox list={facilities} 
              labelKey="phc_name" valueKey="id"
               toggle={toggleFilter} exists={filterExists} 
               name="medical_facility_ids"/>
            </div>
          </div>
        </div>
      </div>

      <div>
        {activeTab === 'kpis' && 
          <>
            {Object.keys(chartFilterGroup).map((g, i) =>
              <Fragment key={i}>
                <div className="card-title card-title-gray m-t-10 flex aligncenter p10 w-full">
                  <h4 className="f18 text-left">{groupLabel[g]}
                  </h4>
                </div>
                <div className="card-body flex aligncenter justifycenter coldir p10">
                  <div className="col-xs-12 mxh-300 scroll-auto">
                    <CheckBox list={chartFilterGroup[g] || []} 
                    labelKey="label" valueKey="sid" 
                    toggle={toggleFilter} exists={filterExists} 
                    name="data_filter_types"/> 
                  </div>
                </div>
              </Fragment>
            )}

            <SavedReportFilterList filter={filter} setFilter={setFilter}/>
          </>
        }
        
        {activeTab === 'org_members' && 
          <Fragment>
            <div className="card-title card-title-gray m-t-10 flex aligncenter p10 w-full">
              <h4 className="f18 text-left">
                Types
                </h4>
            </div>
            <div className="card-body flex aligncenter justifycenter coldir p10">
              <div className="col-xs-12 mxh-300 scroll-auto">
                <CheckBox list={OrgMemberCharts}
                 labelKey="label" valueKey="sid" 
                 toggle={selectFilter} exists={(value, name) => filter[name] == value} 
                 name="report_type"/> 
              </div>
            </div>
          </Fragment>
        }
        
        <div className="card-title card-title-gray m-t-10 flex aligncenter p10 w-full">
          <h4 className="f18 text-left">
            Data Range
            </h4>
        </div>
        <div className="card-body p10">
          {filter.data_range ? 
            null
            :
            <>
              <div className="m-b-5">
                <DateTimePicker name="start_date" onChange={onFilterChanged} 
                dateFormat="YYYY-MM-DD" defaultValue={filter.start_date}
                 className="form-input form-input-gray form-input-full" 
                 placeholder="Start Date"/>
              </div>
              <div className="m-b-5">
                <DateTimePicker name="end_date" onChange={onFilterChanged} 
                dateFormat="YYYY-MM-DD" defaultValue={filter.end_date}
                 className="form-input form-input-gray form-input-full"
                  placeholder="End Date"/>
              </div>
            </>
          }
          {(filter.start_date || filter.end_date) ?
            null
            :
            <div className="m-b-5">
              <select onChange={e => onFilterChanged('data_range', e.target.value)} 
              className="form-select-dropdown w-full form-select-w-100-xs m-b-10-xs">
                <option value="all">Preset date filters</option>
                <OptionsObjList list={PresetDateRange} idKey="sid" labelKey="label"/>
              </select>
            </div>
          }
        </div>
      </div>
    </div>
    <button type="button" className="butn btn-blue w-100p pos-fix b-1" onClick={() => setChartFilter(filter)}>Submit</button>
  </div> 
</div>

   
  )
}

const SelectedFilterItems = ({list, name, toggleFilter}) => {
  return(
    <div className="m-t-5">
      {list?.map((t, i)=>
        <span className="badge-2 m-r-5" key={i} onClick={()=>toggleFilter(t, name, false)}>
          <i className="fas fa-times-circle m-r-5"/>
          {t}
        </span>
      )}
    </div>
  )
}

const MedicalFacilityFilters = ({filter, setFilter, currentUser}) =>{
  const {filteredFacilities:facilities=[], getMedicalFacilities} = useMedicalFacilityApi(currentUser.current_organization_id)

  useEffect(()=>{
    getMedicalFacilities()
  }, [])

  const toggleListFilter = (value, name) => {
    const setter = (list) => {
      if(list?.length === 0){
        delete filter[name]
        setFilter({...filter})
      }else{
        setFilter({...filter, [name]:list})
      }
    }
    FormHelper.toggleListString(value, filter[name], setter)
  }

  const filterListExists = (value, name) => FormHelper.existsListString(value, filter[name])

  return(
    <>
      <div className="card-title card-title-gray m-t-10 flex aligncenter p10 w-full">
        <h4 className="f18 text-left">
          Medical Facilities
        </h4>
      </div>
      <div className="card-body flex aligncenter justifycenter coldir p10">
        <div className="col-xs-12 mxh-300 scroll-auto">
          <CheckBox list={facilities} 
            labelKey="phc_name" valueKey="id" 
            toggle={toggleListFilter} exists={filterListExists} 
            name="medical_facility_ids"/> 
        </div>
      </div>
    </>
  )
}
export {Filters, SelectedFilterItems};