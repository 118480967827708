import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../../services/CommonService';
import useStyle from '../../../hooks/useStyle';
import Pagination from '../../Common/Pagination'
import FileUploadService from '../../../services/FileUploadService';
import { useCurrentUserHook } from '../../Authentication/useUserHook';

let requestParams = {}
const Links = ({journeyProfile, search, journeyType}) => {
  useStyle('chat');
  
  const {currentUser, screen} = useCurrentUserHook()
  const [links, setLinks] = useState([]);

  useEffect(() => {
    requestParams = {
      'sort_column': 'activities.updated_at',
      'sort_direction': 'desc',
      'user_id':currentUser.id,
      'page':1,
      'per_page':15,
      'journey_profile_id':journeyProfile.id,
      'journey_type': journeyType,
      'search': search
    }
    getJourneyHyperLinks();
  }, [search]);

  let getJourneyHyperLinks = (page_no) => {
    requestParams.page = page_no || 1;
    FileUploadService.getJourneyHyperLinks(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setLinks(res.data.links);
      }
    })
  }

  const linkItem = (list) => list.map((c, k) =>
    <div className="col-xs-12 p-5" key={k}>
      <a target="_blank" href={c.value} className="font-15">
        {CommonService.extractHostName(c.value)}
      </a>
    </div>
  )

  const HyperLinks = () =>(
    <Fragment>
      <div className="chat-main" style={{height:screen.height-300}}>
        {links.length==0 && <div className="no-chat font-15">Loading... </div>}
        {Object.values(links).map((l, i) => (
          linkItem(l)  
        ))}
      </div>
      <Pagination totalItems={requestParams.totalItems} currentpage={requestParams.page} setCurrentpage={getJourneyHyperLinks} itemsPerPage={requestParams.per_page}/>
    </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <HyperLinks/>
    </div>
  )
  
}

export default Links;