import React, { Fragment, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import CommonService from '../../../services/CommonService';
import useStyle from '../../../hooks/useStyle';
import Pagination from '../../Common/Pagination'
import FileUploadService from '../../../services/FileUploadService';
import { useCurrentUserHook } from '../../Authentication/useUserHook';

let requestParams = {};
const FileUploads = ({search, journeyProfile}) => {
  useStyle('chat');
  
  const {currentUser, screen} = useCurrentUserHook()
  const [filesList, setFilesList] = useState([]);

  useEffect(() => {
    requestParams = {
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'user_id':currentUser.id,
      'page':1,
      'per_page':15,
      'search':search,
      'journey_profile_id':journeyProfile.id
    }
    getFileUploads();
  }, [search]);
    
  //currentpage
  let getFileUploads = (page_no) => {
    requestParams.page = page_no || 1;
    FileUploadService.getFileUploads(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total;
        setFilesList(res.data.file_uploads);
      }
    })
  }

  const fileItem = filesList.map((c, k) =>
    <div className="col-xs-12 col-sm-4 p-5 wk-center" key={k}>
      <a target="_blank" href={CommonService.getFileUrl(c.url)}>
        <img src={CommonService.getIconByType(c.url)} width="75px" height="75px"/>
      </a>
      {c.filename}
    </div>
  )

  const FileUplaodBox = () =>(
    <Fragment>
      <div className="chat-main" id="chat-main" style={{height:screen.height-300}}>
        {filesList.length < 0 && <div className="no-chat font-15">Loading... </div>}
        {fileItem}
      </div>
      <Pagination totalItems={requestParams.totalItems} currentpage={requestParams.page} setCurrentpage={getFileUploads} itemsPerPage={requestParams.per_page}/>
    </Fragment>    
  ) 

  return(
    <div className="m-t-15">
      <FileUplaodBox/>
    </div>
  )

}

export default FileUploads;