import React, {Fragment, useState, useContext, useEffect, useMemo} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../../components/Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import GenericModal from "../Modals/GenericModal"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { AddBtn, FontAwesomeBtn } from "../Common/Button"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {usePageTitle} from '../../hooks/pageHead'
import CheckAccess from '../Roles/CheckAccess' 
import { JourneyContext } from '../HealthJourney/List/JourneyContext'
import OfflineService from './OfflineService'
import ActivityForm from '../HealthJourney/MotherInfantJourney/Activities/Form/ActivityForm'
import ChildForm from '../HealthJourney/MotherInfantJourney/Child/ChildForm'
import PendingRequests from './PendingRequests'
import {useIdbKeyval} from '../../hooks/useIdbKeyval'
import {useSubscriptionAccess} from '../Subscriptions/useSubscription';
import { useCurrentUserHook } from "../Authentication/useUserHook"

let selectedItem = {}
let requestParams ={}
//https://developer.mozilla.org/en-US/docs/Web/API/Request/json
const FormList = (props) => {
  usePageTitle('Offline Case List');
  
  let params = props.match.params;

  const {currentUser} = useCurrentUserHook()
  const {journeyState, journeyDispatch} = useContext(JourneyContext);

  const [isLoading, setIsLoading] = useState(true);
  const [componentNames, setComponentNames] = useState([]);
  const [journeys] = useState(currentUser?.current_subscription?.journeys);
  const [formProps, setFormProps] = useState({});
  const [requestPendingQue, setRequestPendingQue] = useState({});
  const [journeyList, setJourneyList] = useState([]); //cached list
  const [selectedCase, setSelectedCase] = useState();

  const {isOpen:isFormListOpen, toggleModal:toggleFormListModal} = useModal();
  const {isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal();
  const {isOpen:isPendingRequestOpen, toggleModal:togglePendingRequest} = useModal();
  
  const {subscriptionAccesses} = useSubscriptionAccess();

  const {isStoreLoaded, getByKey} = useIdbKeyval('cacheList');

  useEffect(() => {
    requestParams = {
      'organization_id':currentUser.current_organization_id,
      'locale': currentUser.current_locale
    } 
    getAll();
    getOfflineQue();
  }, [])

  useEffect(()=>{
    if(isStoreLoaded)getCachedJourney();
  }, [isStoreLoaded])

  const mcjForms = {
    'child_birth': ChildForm,
    'default':ActivityForm
  }

  const getCachedJourney = async () => {
    const list = await getByKey('journeyList');
    setJourneyList(list);
  }

  const getAll = () =>{
    setComponentNames(['pins_process_mcj', 'element_ppt_mcj']);
    
    /*OfflineService.getJourneyComponent(requestParams).then(({status, data})=>{
      if(status === 200){
        setComponents(data.journey_components)
        setComponentNames(Object.keys(data.journey_components))
      }
    })*/
  }

  const getOfflineQue = async () =>{
    if(navigator.serviceWorker.controller){
      //check service worker enabled
      return new Promise(function(resolve, reject) {
        const messageChannel = new MessageChannel();
        messageChannel.port1.onmessage = function(event) {
          // The response from the service worker is in event.data
          if (event.data.error) {
            alert(event.data.error);
          } else {
            setRequestPendingQue(Cs.groupBy(event.data, 'form_id'));
          }
        }

        navigator.serviceWorker.controller.postMessage('OfflineQue',
          [messageChannel.port2]
        )
      })
    }
  }

  const openForm = (componentName, component) =>{
    try{
      if(component.sid === 'journey_profile' && !selectedCase.id){
        Cs.routeJourneyForm(component, props.history);
      }else if(componentName.includes('_mcj')){
        if(component.sid === 'child_birth' && selectedCase.child_id){
          //Edit not allowed in offline
          return null;
        }
        
        /*MCJ Journey*/
        let fromProp = Cs.routeInfantPath(component, {}, selectedCase, props.history);
        fromProp.component = (
          mcjForms[component.sid] ? mcjForms[component.sid]:mcjForms['default']
        )
        setFormProps(fromProp);
        toggleFormModal();
      }else if(componentName.includes('_cancer')){
        /*Cancer Journey*/
        //let props = Cs.routeCancerPath(component, {}, props.history)
      }else if(componentName.includes('_dementia')){  
        /*Cancer Journey*/
        //let props = Cs.routeCancerPath(component, {}, props.history)
      }
    }catch(e){
      console.error(e.message);
    }
  }

  const onFormSubmit = (activity={}) =>{
    toggleFormModal();
    getOfflineQue();
    journeyDispatch({'type': "CLEAR"});
  }

  const openPeningRequestList = (form) =>{
    selectedItem = form;
    togglePendingRequest();
  }

  const openFormList = (journeyCase) =>{
    setSelectedCase(journeyCase);
    journeyDispatch({
      "type":"ACTIVE",
      "payload": {'activeJourney':journeyCase}
    })
    toggleFormListModal()
  }

  const TableData = ({component={}, componentName}) => component?.options ? component.options.map((o, k) => {
    return(
      <TR key={k}>
        <TD>
          {o.child_form}
        </TD>
        <TD className="text-gray">
          {o.label}
        </TD>
        <TD className="text-gray" onSelect={()=>openPeningRequestList(o)}>
          <span className="badge-2 bg-lred">
            {requestPendingQue?.[o.child_form]?.length || 0}
          </span>
        </TD>
        <TD>
          <div className="flex aligncenter">
            <LinkFontAwesomeIcon onSelect={()=>openForm(componentName, o)} iconCss="fas fa-plus-circle font-18 white"/>
          </div>
        </TD>
      </TR>
    )
  }):null

  const TableHead = ({componentName}) =>{
    const component = subscriptionAccesses[componentName]
    return(
      <div className="m-b-15">
        <h5 className="m-b-5">{component.label}</h5>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH>
                ID 
              </TH>
              <TH>
                Form
              </TH>
              <TH>
                Pending Cache
              </TH>
              <TH>
                Action
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData component={component} componentName={componentName}/>
          </TBody>
        </Table>
      </div>
    )
  }

  const JourneyTableData = () => journeyList.length > 0 ? journeyList.map((j, k) => {
    return(
      <TR key={k}>
        <TD>
          {j.record_id}
        </TD>
        <TD className="text-gray">
          {j.mother_name}
        </TD>
        <TD className="text-gray">
          {j.mobile_no}
        </TD>
        <TD>
          <div className="flex aligncenter">
            <LinkFontAwesomeIcon onSelect={()=>openFormList(j)}  iconCss="fas fa-plus-circle font-18 white"/>
          </div>
        </TD>
      </TR>
    )
  }):null

  const JourneyTable = ({}) =>{
    return(
      <div className="m-b-15">
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH>
                ID 
              </TH>
              <TH>
                Name
              </TH>
              <TH>
                Mobile
              </TH>
              <TH>
                Action
              </TH>
            </TR>
          </THead>
          <TBody>
            <JourneyTableData/>
          </TBody>
        </Table>
      </div>
    )
  }

  const FormList = () => (
    <div id="ct" className="p-l-5 p-r-5">
      {componentNames.map((f, i)=>
        <TableHead componentName={f} key={i}/> 
      )}
    </div>
  )

  return (
    <>
      <div id="ct">
        <div className="app-content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Offline Case List</div>
              <div className="m-r-20 font-12">
                To add a case to the offline list, go to the home page and open the case details page for that case.
              </div>
            </div>
          </div>

        <div className="app-content-section m-t-30 table-responsive">
          <JourneyTable/>
        </div>
        </div>
      </div>

      {isFormListOpen &&
        <GenericModal title="Forms" component={FormList} toggleModal={toggleFormListModal} />
      }
      
      {isFormOpen && 
        <GenericModal toggleModal={toggleFormModal} 
        isPopupMode="true" onFormSubmit={onFormSubmit} 
        {...formProps} />
      }

      {isPendingRequestOpen && 
        <GenericModal toggleModal={togglePendingRequest} 
        component={PendingRequests} 
        pendingRequests={requestPendingQue[selectedItem.child_form]} 
        dataSourceList={selectedItem}/>
      }
    </>
  )

}

export default FormList;